import Service from './Service';

class EdrStdMapperService extends Service {
    constructor() {
        super();
    }
    async get(pageParam) {
        return this.callApiGet(`/edrStdMapper${pageParam}`);
    }
    async create(postData) {
        return this.callApiPost(`/edrStdMapper`, postData);
    }
    async update(postData) {
        return this.callApiPut(`/edrStdMapper`, postData);
    }
    async delete(id) {
        return this.callApiDelete(`/edrStdMapper?_ids=${id}`);
    }
}
export default EdrStdMapperService